//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
import cookies from "vue-cookies";
export default {
  data: () => ({
    menuItems: [
      {
        icon: "mdi-map-marker",
        title: "Ubicació de la tasca",
        action: "customergpsloc",
      },
      {
        icon: "mdi-map-marker-star",
        title: "Ubicació de l'equip",
        action: "elementgpsloc",
      },
      {
        icon: "mdi-account-alert",
        title: "Alerta de client",
        action: "gocustomeralert",
      },
      { icon: "mdi-camera", title: "Fotos", action: "gophotos" },
      { icon: "mdi-head-remove", title: "Estat de l'ordre", action: "gostate" },
      { icon: "mdi-list-box", title: "Resum de material i feina", action: "gosummary" },
      {
        icon: "mdi-alert-outline",
        title: "Treballs extres realitzats",
        action: "goworkperformed",
      },
      { icon: "mdi-history", title: "Historial", action: "gohistory" },
      { icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" },
      { icon: "mdi-exit-to-app", title: "LogOut", action: "logout" },
    ],
    itemOne: "",
    orderStateColor: "",
    taskList: [],
    dialogWarningVisible: false,
    dialogWarningDisabled: "0",
    dialogWarningCookiePrefix: "WorkCalendarEdit_dialogWarningDisabled",
    dialogWarningCookieName: "WorkCalendarEdit_dialogWarningDisabled",
    dialogWarningCookieValue: "",
    warningList: [],
    stateList: [
      {
        icon: "mdi-calendar-check",
        iconClass: "green white--text",
      },
      {
        icon: "mdi-calendar",
        iconClass: "red white--text",
      },
      {
        icon: "mdi-calendar",
        iconClass: "red white--text",
      },
    ],
  }),
  mounted() {
    this.getItem();
  },
  methods: {
    goBack() {
      this.$router.push("/workcalendarlist");
    },
    goMaps(url) {
      window.open(url);
    },
    goMenu(action) {
      switch (action) {
        case "logout":
          sessionStorage.setItem("Token", "");
          this.$router.push("/");
          break;
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
        case "customergpsloc":
          if (this.itemOne.strCenterGPSLoc.length == 0) {
            this.goMaps(this.itemOne.strCustomerGPSLoc);
          } else {
            this.goMaps(this.itemOne.strCenterGPSLoc);
          }
          break;
        case "elementgpsloc":
          if (this.itemOne.strElementGPSLoc.length == 0) {
            if (this.itemOne.strCenterGPSLoc.length == 0) {
              this.goMaps(this.itemOne.strCustomerGPSLoc);
            } else {
              this.goMaps(this.itemOne.strCenterGPSLoc);
            }
          } else {
            this.goMaps(this.itemOne.strElementGPSLoc);
          }
          break;
        case "gocustomeralert":
          this.dialogWarningVisible = true;
          break;
        case "gohistory":
          sessionStorage.setItem("sessCustomerId", this.itemOne.customerId);
          sessionStorage.setItem("sessElementId", this.itemOne.elementId);
          this.$router.push("/workorderhistory");
          break;
        case "gostate":
          this.$router.push("/workorderstate");
          break;
        case "gosummary":
          sessionStorage.setItem("sessDocLineId", 0);
          this.$router.push("/workorder02listsummary");
          break;
        case "goworkperformed":
          // this.$router.push("/workperformed"); //({ path: '/about', hash: '#team' }
          // this.$router.push({ path: "/workperformed", query : { color: this.orderStateColor} });
          this.$router.push({
            name: "WorkPerformed",
            params: { color: this.orderStateColor },
          });
          break;
        case "gocomplete":
          this.$router.push("/workordercomplete");
          break;
        default:
      }
    },
    goRealitzat(docLineId) {
      if (this.getOrderActive()) {
        sessionStorage.setItem("sessDocLineId", docLineId);
        this.$router.push("/workorder02list");
      } else {
        this.msgAlert("No active order!", true);
      }
    },
    goStandby() {
      this.$router.push("/workorderstandby");
    },
    getTittle(item) {
      var t = item.docDate + " " + item.docTime + " a ";
      if (item.endDate != "0000-00-00") {
        t = t + item.endDate + " " + item.endTime;
      } else {
        t = t + " ...";
      }
      return t;
    },
    getOrderActive() {
      return !(
        this.itemOne.orderState == "W" || this.itemOne.orderState == "C"
      );
    },
    getStyle(endDate) {
      if (endDate == 0) {
        return "vertical-align: middle color: red";
      } else {
        return "vertical-align: middle color: black";
      }
    },
    getItem() {
      this.taskList = [];
      this.warningList = [];
      axios
        .post(api.URL() + "/api/v1/gagendaoperaris_getbyid", {
          token: sessionStorage.getItem("Token"),
          docType: parseInt(sessionStorage.getItem("sessDocType")),
          docCompanyId: parseInt(sessionStorage.getItem("sessDocCompanyId")),
          docYear: parseInt(sessionStorage.getItem("sessDocYear")),
          docSerialId: parseInt(sessionStorage.getItem("sessDocSerialId")),
          docId: parseInt(sessionStorage.getItem("sessDocId")),
          docLineId: 0, //parseInt(sessionStorage.getItem("sessDocLineId")),
          docSublineId: 0, //parseInt(sessionStorage.getItem("sessDocSublineId")),
          docCount: parseInt(sessionStorage.getItem("sessDocCount")),
        })
        .then((response) => {
          if (response.error == null) {
            this.itemOne = response.data;
            this.getTasks();
            this.getWarnings(
              this.itemOne.customerId,
              this.itemOne.orderWorkPlace
            );
            this.omplirValors(this.itemOne);
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    getTasks() {
      var self = this;
      self.taskList = [];
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio01_getitemsbydoc", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: this.itemOne.docCompanyId,
          docYear: this.itemOne.docYear,
          docSerialId: this.itemOne.docSerialId,
          docId: this.itemOne.docId,
          userId: parseInt(sessionStorage.getItem("UserId")),
        })
        .then((response) => {
          if (response.error == null) {
            if (response.data != null) {
              self.taskList = response.data;
            }
            self.omplirValors(this.itemOne);
          }
        })
        .catch(function (error) {
          self.msgAlert(error, true);
        });
    },
    getWarnings(customerId, orderWorkPlace) {
      var self = this;
      axios
        .post(api.URL() + "/api/v1/gcontactesavis_getbycustomerid", {
          token: sessionStorage.getItem("Token"),
          customerId: customerId,
          orderWorkPlace: orderWorkPlace,
          warnigType: "#ALERTA_OPERARI#",
        })
        .then((response) => {
          if (response.error == null) {
            self.warningList = [];
            if (response.data != null) {
              self.warningList = response.data;
              self.dialogWarningCookieValue = self.warningList[0].lastUpdate;
              self.dialogWarningCookieName =
                self.dialogWarningCookiePrefix + customerId;
              var ckValue = cookies.get(self.dialogWarningCookieName);
              if (
                self.warningList.length > 0 &&
                self.warningList[0].lastUpdate <= ckValue
              ) {
                if (ckValue != null && ckValue.indexOf("*") == -1) {
                  // "*" es cookie temporal
                  self.dialogWarningDisabled = "1";
                } else {
                  self.dialogWarningDisabled = "0";
                }
                self.dialogWarningVisible = false;
              } else {
                self.dialogWarningDisabled == "0";
                self.dialogWarningVisible = true;
                self.updateDialogWarningDisabled("0");
              }
            }
          } else {
            self.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          self.msgAlert(error, true);
        });
    },
    omplirValors(itemOne) {
      if (this.taskList.length == 0 || this.taskList[0].calcSeconds > 0) {
        //No hi ha cap tasca o la ultima tasca ja esta tancada
        this.orderStateColor = "orange lighten-2";
      } else {
        this.orderStateColor = "blue lighten-2";
      }
      if (itemOne.orderState == "W") {
        this.orderStateColor = "red lighten-2";
      }
      if (itemOne.orderState == "C") {
        this.orderStateColor = "green lighten-2";
      }
    },
    updateTask() {
      var self = this;
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio01_add", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: this.itemOne.docCompanyId,
          docYear: this.itemOne.docYear,
          docSerialId: this.itemOne.docSerialId,
          docId: this.itemOne.docId,
          userId: parseInt(sessionStorage.getItem("UserId")),
          onlyOne: true,
          text: "",
        })
        .then(() => {
          self.getTasks();
        })
        .catch(function (error) {
          self.msgAlert(error.response.data, true);
        });
    },
    updateDialogWarningDisabled(value) {
      if (value == "0") {
        cookies.set(
          this.dialogWarningCookieName,
          this.dialogWarningCookieValue + "*",
          "5min"
        );
      } else {
        cookies.set(
          this.dialogWarningCookieName,
          this.dialogWarningCookieValue,
          "30d"
        );
      }
    },
    calcMinutes(minutes) {
      var h;
      var m;
      var r;
      h = Math.floor(minutes / 60);
      m = minutes % 60;
      r = "";
      if (h > 0) {
        if (h == 1) {
          r = r + "1 hora";
        }
        if (h > 1) {
          r = r + h.toString() + " hores";
        }
      }
      if (m > 0) {
        if (r != "") {
          r = r + " ";
        }
        if (m == 1) {
          r = r + "1 minut";
        }
        if (m > 1) {
          r = r + m.toString() + " minuts";
        }
      }

      return r;
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
